import React from "react";
import { useSelector } from "react-redux";
import { baseUrl } from "../../config";

export default function ModalImg({imgSrc}) {
  // const imgSrc = useSelector((state) => state.mainuniversity?.modalDynamicImg);
  return (
    <>
      <div
        className="modal fade connsult-wrap corseimg-popup"
        id="modalLoginImg"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body consult-body mx-3">
              <div className="row">
                <div className="col-lg-12">
                  <div className="course-feeimg consult-img">
                    {imgSrc === "" ? (
                      <h3>No Image</h3>
                    ) : (
                      <img
                        className="img-circle"
                        src={imgSrc && imgSrc}
                        alt=""
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
