import React from "react";
import Style from "../../assets/styles/ModuleStyle/course.module.css";
import { Link } from "react-router-dom";
import { baseUrl } from "../../config";
import Parser from "../Elements/Parser";

export default function GyanBlogDetails({ blogDetails }) {
  return (
    <>
      <div className={Style.blog_content}>
        <div className={Style.blog_title}>{blogDetails?.title}</div>
        <div className={Style.blog_meta}>
          <ul>
            <li>
              Post on <Link to="#">{blogDetails?.date_time}</Link>
            </li>
            <li>
              By <Link to="#">{blogDetails?.published_by}</Link>
            </li>
          </ul>
        </div>
        <div className={Style.blog_image}>
          <img src={`${baseUrl}${blogDetails?.image}`} alt="" />
        </div>
       
        <Parser content={blogDetails?.content}/>
      </div>
      <div
        className={`${Style.blog_extra} mb-5 d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-start`}
      >
        <div className={Style.blog_tags}>
          <span>Tags: </span>
          <ul>
            <li>
              <Link to="#">{blogDetails?.published_by}</Link> ,{" "}
            </li>
            {/* <li>
              <Link to="#">Collage Name</Link>{" "}
            </li> */}
          </ul>
        </div>
        <div className={`${Style.blog_social} ml-lg-auto`}>
          <span>Share: </span>
          <ul>
            <li>
              <a
                href={`https://wa.me/?text=${encodeURIComponent(blogDetails?.title + ' ' + window.location.href)}`}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Share on WhatsApp"
              >
                <i className="fa fa-whatsapp" aria-hidden="true" />
              </a>
            </li>
            <li>
              <Link
                to={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Share on Facebook"
              >
                <i className="fa fa-facebook" aria-hidden="true" />
              </Link>
            </li>
            <li>
              <Link
                to={`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent(blogDetails?.title)}`}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Share on Twitter"
              >
                <i className="fa fa-twitter" aria-hidden="true" />
              </Link>
            </li>
            <li>
              <Link
                to={`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(window.location.href)}&title=${encodeURIComponent(blogDetails?.title)}`}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Share on LinkedIn"
              >
                <i className="fa fa-linkedin" aria-hidden="true" />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
