import { lazy, Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import AppContext from  "./Context/AppContext";
import CourseSpecializationDetails from "./pages/CouseSpecializationDetails";
import Videodetails from "./pages/videodetails";
import Gyannewsdetails from "./pages/Gyannewsdetails";
import Universityvideodetails from "./pages/universityvideodetails";
import Expertvideolist from "./pages/expertvideolist";

// const AppContext = lazy(() => import("./Context/AppContext"));
const GeneralCourse = lazy(() => import("./pages/GeneralCourse"));
const Home = lazy(() => import("./pages/Home"));
const Course = lazy(() => import("./pages/Course"));
const CourseCategoryExplore = lazy(() => import("./pages/CourseCategoryExplore"));
const GeneralSpecializationCourse = lazy(() => import("./pages/GeneralSpecializationCourse"));
const Expert = lazy(() => import("./pages/Expert"));
const University = lazy(() => import("./pages/University"));
const Blog = lazy(() => import("./pages/Blog"));
const BlogsPage = lazy(() => import("./pages/BlogsPage"));
const OnlineMba = lazy(() => import("./pages/OnlineMba"));
const Comparison = lazy(() => import("./pages/Comparison"));


function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const [openModal, setOpenModal] = useState(false);
  const [course, setCourse] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  function handleOpenModalWithCourseTrue() {
    setOpenModal(true);
    setCourse(true);
  }
  function handleOpenModalWithCourseFalse() {
    setOpenModal(true);
    setCourse(false);
  }
  useEffect(() => {
    const checkWidth = () => {
      if (window.innerWidth <= 600) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    checkWidth();
    window.addEventListener("resize", checkWidth);
    return () => window.removeEventListener("resize", checkWidth);
  }, []);
  return (
    <AppContext.Provider
      value={{
        isMobile,
        handleOpenModalWithCourseTrue,
        handleOpenModalWithCourseFalse,
      }}
    >
      <Suspense fallback={<h3>Loading ...</h3>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/course/:uniName/:courseName" element={<Course />} />
          <Route path="/explore-course-category/:name" element={<CourseCategoryExplore />} />
          <Route path="/course/couse-specialization-details/:name" element={<CourseSpecializationDetails />} />
          <Route path="/general-specialization-course/:generalCourseName" element={<GeneralSpecializationCourse />} />
          <Route path="/general-course/:courseName" element={<GeneralCourse />} />
          <Route path="/expert/:expertId" element={<Expert />} />
          <Route path="/university/:uniName" element={<University />} />
          <Route path="/university/videodetails/:unid/:videoId" element={<Videodetails />} />
          <Route path="/university/videodetails/:unid" element={<Universityvideodetails />} />
          <Route path="/university/expertvideolist/:uniName" element={<Expertvideolist />} />
          <Route path="/blog-list/:catId" element={<Blog />} />
          <Route path="/blog-details/:title" element={<BlogsPage />} />
          <Route path="/gyannews-details/:title" element={<Gyannewsdetails />} />
          <Route path="/onlinemba/:searchTerm" element={<OnlineMba />} />
          <Route path="/comparison/:universityIds" element={<Comparison />} />
        </Routes>
      </Suspense>

    </AppContext.Provider>
  );
}

export default App;
