import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import CrStyle from "../../assets/styles/ModuleStyle/course.module.css";
import owlStyle from "../../assets/styles/ModuleStyle/customOwl.module.css";
import { responsiveCarousel } from "../../utils/constants";
import { baseUrl } from "../../config";

export default function StudentManse2({ details, key }) {
  const [componentKey, setComponentKey] = useState(Date.now());
  useEffect(()=>{
    console.log("Student man se ,", details);
  },[details, key]);

  useEffect(() => {
    const handleRefresh = () => {
      setComponentKey(Date.now());
    };
    window.addEventListener("load", handleRefresh);
    return () => window.removeEventListener("load", handleRefresh);
  }, []);
  return (
    <div
      id="Student_Mann_Se"
      className="student_mannse background-dark section"
    >
      <div className="container" data-aos="fade-in">
        <div className="row">
          <div className="col">
            <div className="text-left">
              <h2 className="section_title">Students Mann Se! </h2>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <OwlCarousel
            loop
            id="university_stdt_mannseblog"
            className="owl-carousel owl-theme"
            nav
            dots={false}
            stageOuterClass={owlStyle.owlStageOuterSpec}
            navText={[
              "<i class='fa fa-chevron-left'></i>",
              "<i class='fa fa-chevron-right'></i>",
            ]}
            navContainerClass={owlStyle.containerNav}
            navClass={["navPrev", "navNext"]}
            responsive={responsiveCarousel}
          >
            {details?.map((item, index) => (
              <div
                className="item col-lg-12"
                data-aos="fade-in"
                data-aos-delay={100}
              >
                <div
                  className={`${CrStyle.internal_box}  ${CrStyle["student-box"]} student-box`}
                >
                  <div className={CrStyle.std_img}>
                    <img
                      className="img-fluid"
                      src={`${baseUrl}${item.image}`}
                      alt=""
                    />
                  </div>
                  <h6
                    className={`${CrStyle["student-mannse-Title"]} student-mannse-Title mb-2`}
                  >
                    {item.name}
                  </h6>
                  <div
                    className={`${CrStyle["batch-time"]} d-flex flex-row align-items-center justify-content-between`}
                  >
                    <p>
                      <b>Batch: </b> {item.batch}
                    </p>
                    <p className="min-col">129 min. ago</p>
                  </div>
                  <div className={CrStyle["Blog-View-Desc"]}>
                    {item.description}
                  </div>
                </div>
              </div>
            ))}
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
}
