import React, { useEffect, useState, useRef } from 'react';
import CommonLayout from '../layouts/CommonLayout';
import { useParams } from 'react-router-dom';
import './videdetails.css';
import uniqid from 'uniqid';
import { apiBaseUrl, baseUrl } from '../config';
import OwlCarousel from 'react-owl-carousel';
import owlStyle from '../assets/styles/ModuleStyle/customOwl.module.css';
import { Link } from 'react-router-dom';
import ExpertAdvice from '../components/ExpertAdvice';
import NewsLetter from '../components/NewsLetter';

function Universityvideodetails() {
    const { unid, videoId } = useParams();
    const [videodetailsdata, setVideodetailsdata] = useState([]);
    const [selectedVideoId, setSelectedVideoId] = useState(videoId || null);
    const [loading, setLoading] = useState(false);
    const videoSectionRef = useRef(null);

    // Fetch video details
    useEffect(() => {
        const fetchVideoDetails = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${apiBaseUrl}univercity-video-list/${unid}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setVideodetailsdata(data?.video_list || []);
                if (!videoId && data?.video_list?.length > 0) {
                    setSelectedVideoId(data.video_list[0].id);
                }
            } catch (error) {
                console.error('Error fetching video details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchVideoDetails();
    }, [unid, videoId]);

    // Scroll to the video section when the component mounts
    useEffect(() => {
        if (videoSectionRef.current) {
            videoSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [videodetailsdata]);

    // Get selected video details
    const selectedVideo = videodetailsdata.find((video) => video.id === selectedVideoId) || videodetailsdata[0];

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <CommonLayout>
            <div className="university video_title">
                <div className="breadcrumbs_container compair_crumbs">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="university_title">Video</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Video section with ref */}
            <div className="video-play pb-4" ref={videoSectionRef}>
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-10">
                            <div className="embed-responsive embed-responsive-16by9">
                                {selectedVideo && (
                                    <iframe
                                        className="embed-responsive-item"
                                        src={selectedVideo.video_url}
                                        title={selectedVideo.title}
                                    ></iframe>
                                )}
                            </div>

                            {selectedVideo && (
                                <div className="video-tital mt-4">
                                    <h4 className="font-weight-bold">{selectedVideo.title}</h4>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* Carousel Section */}
            <div className="video-row">
                <div className="container">
                    <OwlCarousel
                        id="University_experts"
                        className="owl-carousel owl-theme row"
                        loop
                        nav
                        dots={false}
                        stageOuterClass={owlStyle.owlStageOuterSpec}
                        navText={[
                            "<i class='fa fa-chevron-left'></i>",
                            "<i class='fa fa-chevron-right'></i>",
                        ]}
                        navContainerClass={owlStyle.containerNav}
                        navClass={['navPrev', 'navNext']}
                        items={3}
                        autoplay={true}
                        autoplayHoverPause={true}
                        responsive={{
                            0: {
                                items: 1,
                                autoplay: true,
                            },
                            600: {
                                items: 2,
                                autoplay: true,
                            },
                            1000: {
                                items: 3,
                                autoplay: true,
                            },
                        }}
                    >
                        {videodetailsdata.map((item) => (
                            <div
                                key={uniqid()}
                                className="expertwrap thumbnail item col fea_university_col"
                            >
                                <div className="fev-colleges">
                                    <div className="expert-wrap fea-university_image">
                                        <Link
                                            to="#"
                                            onClick={() => setSelectedVideoId(item.id)}
                                        >
                                            <img
                                                src={`${baseUrl}${item.image}`}
                                                alt={item.title}
                                            />
                                        </Link>
                                        <div className="youtube-icon">
                                            <Link
                                                to="#"
                                                onClick={() => setSelectedVideoId(item.id)}
                                            >
                                                <i className="uil uil-youtube" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="fea-university_body">
                                        <h3 className="fea-university_title">
                                            <Link
                                                // to={`/university/videodetails/${unid}/${item.id}`}
                                                onClick={() => setSelectedVideoId(item.id)}
                                            >
                                                {item.title}
                                            </Link>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </OwlCarousel>
                </div>
            </div>

            <ExpertAdvice getModal="#modalLoginForm2" />
            <NewsLetter />
        </CommonLayout>
    );
}

export default Universityvideodetails;
