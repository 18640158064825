import React, { useEffect, useState } from "react";

import Style from "../../assets/styles/ModuleStyle/course.module.css";
import LogoSlider from "../Elements/LogoSlider";
import { useParams } from "react-router-dom";
import { apiBaseUrl } from "../../config";
import { toast, ToastContainer } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";

export default function CourseMainModal() {
  const [courses, setCourses] = useState([]);
  const { uniName } = useParams();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    course: "",
    message: "",
  });
  const [isVerified, setIsVerified] = useState(false);

  const handleRecaptcha = (value) => {
    const timeout = new Promise(
      (_, reject) =>
        setTimeout(() => reject(new Error("ReCAPTCHA Timeout")), 5000) // 5 seconds
    );

    Promise.race([new Promise((resolve) => resolve(!!value)), timeout])
      .then((result) => setIsVerified(result))
      .catch((error) => console.error("ReCAPTCHA Error:", error));
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isVerified) {
      alert("Please verify the CAPTCHA to proceed.");
      return;
    }

    const payload = {
      univercity_name: uniName,
      name: formData.name,
      email: formData.email,
      phone: formData.mobile,
      course_id: formData.course,
      message: formData.message,
    };

    console.log("payload", payload);

    try {
      const response = await fetch(`${apiBaseUrl}submit-univercity-review`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
         await response.json();
        toast.success("Review submitted successfully!");
        setFormData({
          name: "",
          email: "",
          mobile: "",
          course: "",
          message: "",
        });
        setIsVerified(false);
      } else {
        const error = await response.json();
        toast.error(
          `Failed to submit review: ${error.message || "Unknown error"}`
        );
      }
    } catch (error) {
      console.error("Error submitting review:", error);
      toast.error("An error occurred while submitting the review.");
    }
  };

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await fetch(
          `${apiBaseUrl}univercity-course-list/${uniName}`
        );
        const data = await response.json();
        setCourses(data?.course_list);
      } catch (err) {
        console.log(err.message);
      } 
    };

    fetchCourses();
  }, [uniName]);
  return (
    <>
      <div
        className="modal fade connsult-wrap"
        id="modalLoginForm2"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h4 className="modal-title w-100 font-weight-bold">
                Need a Boost in your Professional Journey?
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body consult-body mx-3">
              <div className="row">
                <div className="col-lg-5">
                  <div className="consult-details">
                    <LogoSlider />
                    <div className={Style["spec-title"]}>
                      <h4>
                        On a single platform, compare the best colleges in India{" "}
                      </h4>{" "}
                    </div>
                    <div className={Style["spec-le-img"]}>
                      <img src="/image/form-img.png" alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-7">
                  <h3 className="modal-title w-100 font-weight-bold">
                    Make a Quick Inquiry
                  </h3>
                  <ul className="d-flex justify-content-start flex-md-row flex-sm-column list-unstyled mt-2 lead-form-lead-point">
                    <li className="mx-1">
                      <i className="uil uil-shield-check" /> Get Approved
                      University
                    </li>
                    <li className="mx-1">
                      <i className="uil uil-shield-check" /> 100% Placement
                      Assistance
                    </li>
                  </ul>
                  <div className="consult-form contact_form">
                    <form
                      method="POST"
                      onSubmit={handleSubmit}
                      className="comment_form"
                    >
                      <div>
                        {/* <div className=form_title">Name</div>*/}
                        <input
                          name="name"
                          type="text"
                          placeholder="Name"
                          className="comment_input"
                          required
                          value={formData.name}
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        {/*  <div className=form_title">Email</div>*/}
                        <input
                          name="email"
                          type="text"
                          placeholder="Email"
                          className="comment_input"
                          required
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          {/* <div className=form_title">Mobile</div>*/}
                          <input
                            name="mobile"
                            type="tel"
                            placeholder="Mobile"
                            className="comment_input"
                            required="required"
                            value={formData.mobile}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-lg-6 mt-lg-0 mt-2">
                          {/*  <div className=form_title">Select Course</div>*/}
                          <select
                            name="course"
                            placeholder="Select Course"
                            id="counter_select"
                            className="counter_input counter_options"
                            value={formData.course}
                            onChange={handleChange}
                          >
                            <option>Choose Course</option>
                            {courses?.map((item, index) => (
                              <option key={index} value={item?.name}>
                                {item?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div>
                        {/*<div className=form_title">Message</div>*/}
                        <textarea
                          name="message"
                          placeholder="Message"
                          className="comment_input comment_textarea"
                          required="required"
                          value={formData.message}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="py-2 text-start d-block">
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                          onChange={handleRecaptcha}
                        />
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="comment_button trans_200"
                        >
                          submit now
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/*<div className="modal-footer d-flex justify-content-center">
               <button className=btn btn-default">Login</button>
                </div>*/}
          </div>
        </div>
      </div>
    </>
  );
}
