import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import owlStyle from "../assets/styles/ModuleStyle/customOwl.module.css";
import { Link } from "react-router-dom";
import uniqid from "uniqid";
import { useDispatch, useSelector } from "react-redux";
import { apiBaseUrl, baseUrl } from "../config";

import parser from "html-react-parser";

function LatestUpdates({ condition, uniName }) {
  const [universityNews, setUniversityNews] = useState([]);
  const [ExpertReview, setExpertReview] = useState([]);
  const [tabFeed, setTabFeed] = useState(1);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (condition && universitynews) {
  //     setUniNewsList(
  //       universitynews.filter(
  //         (item) => item.universities_id === Number(condition)
  //       )
  //     );
  //   } else {
  //     setUniNewsList(universitynews || []);
  //   }
  // }, [condition, universitynews]);

  // useEffect(() => {
  //   // Update expert reviews list based on the condition
  //   if (condition && expertList) {
  //     setExptList(
  //       expertList.filter(
  //         (item) => item.universities_id === Number(condition)
  //       )
  //     );
  //   } else {
  //     setExptList(expertList || []);
  //   }
  // }, [condition, expertList]);

  const fetchExpertReviews = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${apiBaseUrl}univercity-expert-review-list/${uniName}`);
      const data = await response.json();
      setLoading(false);
      if (data.status === "success") {
        setExpertReview(data?.review_list || []);
      } else {
        console.error("Invalid response format", data);
      }
    } catch (error) {
      console.error("Error fetching expert reviews:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUniversityNews = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${apiBaseUrl}univercity-gayan-news/${uniName}`);
      const data = await response.json();
      setLoading(false);
      if (data.status === "success") {
        setUniversityNews(data?.gayan_news_details || []);
      } else {
        console.error("Invalid response format", data);
      }
    } catch (error) {
      console.error("Error fetching university news:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchExpertReviews();
    fetchUniversityNews();
  }, [apiBaseUrl]);

  return (
    <>
      <div
        id="Latest_Update"
        className="Latest_Update Featured-University section"
      >
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col">
              <div className="text-left">
                <h2 className="section_title">Latest University Updates</h2>
              </div>
            </div>
          </div>
          <div className="row" data-aos="fade-in" data-aos-delay={100}>
            {/* Nav tabs */}
            <div className="card">
              <ul
                id="myTab"
                role="tablist"
                className="nav nav-tabs nav-pills bg-light border-0 rounded-nav"
              >
                <li className="nav-item" style={{ cursor: "pointer" }}>
                  <div
                    id="uni-news-tab"
                    role="tab"
                    className={
                      tabFeed === 1
                        ? "nav-link border-0 font-weight-bold active"
                        : "nav-link border-0 font-weight-bold "
                    }
                    onClick={() => setTabFeed(1)}
                  >
                    UNIVERSITY NEWS
                  </div>
                </li>
                <li className="nav-item" style={{ cursor: "pointer" }}>
                  <div
                    id="uni-expert-tab"
                    role="tab"
                    className={
                      tabFeed === 2
                        ? "nav-link border-0 font-weight-bold active"
                        : "nav-link border-0 font-weight-bold "
                    }
                    onClick={() => setTabFeed(2)}
                  >
                    EXPERT REVIEWS
                  </div>
                </li>
              </ul>
              <div id="myTabContent" className="tab-content">
                {tabFeed === 1 && (
                  <div
                    id="uni-news"
                    role="tabpanel"
                    aria-labelledby="uni-news-tab"
                    className={
                      tabFeed === 1
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                  >
                    {loading ? (
                      <h3>Loading...</h3>
                    ) : (
                      <div>
                        {universityNews?.length > 0 ? (
                          <OwlCarousel
                            loop
                            nav
                            dots={false}
                            stageOuterClass={owlStyle.owlStageOuterSpec}
                            navText={[
                              "<i class='fa fa-chevron-left'></i>",
                              "<i class='fa fa-chevron-right'></i>",
                            ]}
                            navContainerClass={owlStyle.containerNav}
                            navClass={["navPrev", "navNext"]}
                            id="University_Blogs"
                            className="owl-carousel owl-theme row"
                            items={3}
                            autoplay={true}
                            autoplayHoverPause={true}
                            responsive={{
                              0: {
                                items: 1,
                                autoplay: true,
                              },
                              600: {
                                items: 2,
                                autoplay: true,
                              },
                              1000: {
                                items: 3,
                              },
                            }}
                          >
                            {universityNews &&
                              universityNews.map((item, index) => (
                                <div
                                  key={uniqid()}
                                  className="expertwrap thumbnail item col fea_university_col"
                                  data-aos="fade-in"
                                  data-aos-delay={100}
                                >
                                  <div className="fev-colleges">
                                    <div className="fea-university_image">
                                      <img src={`${baseUrl}${item.image}`} alt="" />
                                    </div>
                                    <div className="fea-university_body">
                                      <h3 className="fea-university_title"> 
                                        <Link to={`/gyannews-details/${item.title}`}>{item.title}</Link>
                                      </h3>
                                    </div>
                                    <div className="fea-university_footer">
                                      <div className="publishcol fea-university_footer_content d-flex flex-row align-items-center justify-content-between">
                                        <p>By: {item.published_by}</p>
                                        <p className="timeby">{item.ago} min. ago.</p>
                                      </div>
                                      <div className="blog-content">
                                        <p>{item.description}</p>
                                      </div>
                                      <div className="fea-university-button">
                                        <Link className="btn fea-button" to={`/gyannews-details/${item.title}`}>
                                          Read More <i className="uil uil-angle-right" />
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </OwlCarousel>
                        ) : (
                          <h3>No University News Available</h3>
                        )}
                      </div>

                    )}
                  </div>
                )}
                {tabFeed === 2 && (
                  <div
                    id="uni-expert"
                    role="tabpanel"
                    aria-labelledby="uni-expert-tab"
                    className={
                      tabFeed === 2
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                  >
                    {loading ? (
                      <h3>Loading...</h3>
                    ) : (
                      <div>
                         {ExpertReview?.length > 0 ? (
                      <OwlCarousel
                        id="University_experts"
                        className="owl-carousel owl-theme row"
                        loop
                        nav
                        dots={false}
                        stageOuterClass={owlStyle.owlStageOuterSpec}
                        navText={[
                          "<i class='fa fa-chevron-left'></i>",
                          "<i class='fa fa-chevron-right'></i>",
                        ]}
                        navContainerClass={owlStyle.containerNav}
                        navClass={["navPrev", "navNext"]}
                        items={3}
                        autoplay={true}
                        autoplayHoverPause={true}
                        responsive={{
                          0: {
                            items: 1,
                            autoplay: true,
                          },
                          600: {
                            items: 2,
                            autoplay: true,
                          },
                          1000: {
                            items: 3,
                            autoplay: true,
                          },
                        }}
                      >
                        {ExpertReview &&
                          ExpertReview.map((item, index) => (
                            <div
                              key={uniqid()}
                              className="expertwrap thumbnail item col fea_university_col"
                              data-aos="fade-in"
                              data-aos-delay={100}
                            >
                              <div className="fev-colleges">
                                <div className="expert-wrap fea-university_image">
                                  <Link to={`/university/videodetails/${item.universities_id}/${item.id}`}>
                                    <img
                                      src={`${baseUrl}${item.video_thumbnail}`}
                                      alt=""
                                    />
                                  </Link>
                                  <div className="youtube-icon">
                                    <Link to={`/university/videodetails/${item.universities_id}/${item.id}`}>
                                      <i className="uil uil-youtube" />
                                    </Link>
                                  </div>
                                </div>
                                <div className="fea-university_body">
                                  <h3 className="fea-university_title">
                                    <Link to="#">{item.title}</Link>
                                  </h3>
                                </div>
                                <div className="fea-university_footer">
                                  <div className="publishcol fea-university_footer_content d-flex flex-row align-items-center justify-content-between">
                                    <p>By: {item.posted_by}</p>
                                    <p className="timeby">{item.ago} min. ago.</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </OwlCarousel>
                       ) : (
                        <h3>No Expert Review Available</h3>
                      )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(LatestUpdates);
 