import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import owlStyle from "../assets/styles/ModuleStyle/customOwl.module.css";
import { apiBaseUrl, baseUrl } from "../config";
import { Link } from "react-router-dom";
import FeaturedCarousel from "./CustomCarousel/FeaturedCarousel";

export default function TopUniversity() {
  const [topUniversityList, setTopUniversityList] = useState({});
  const [loading, setLoading] = useState(false);
  const fetchTopUniversity = () => {
    setLoading(true);
    fetch(`${apiBaseUrl}top-univercity`)
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data.status === "success") {
          setTopUniversityList(data);
        } else {
          console.error("Invalid response format", data);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching banner image:", error);
      });
  };

  useEffect(() => {
    fetchTopUniversity();
  }, []);
  return (
    <>
      <div id="Top_UG_university" className="Top_university section">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col pb-4">
              <div className="section_title text-left">
                <h2 className="section_title">Top Universities in India</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {loading ? (
              <h3>Loading...</h3>
            ) : (
              <OwlCarousel
                id="top_university"
                className="owl-carousel owl-theme row"
                loop
                nav
                dots={false}
                stageOuterClass={owlStyle.owlStageOuterSpec}
                navText={[
                  "<i class='fa fa-chevron-left'></i>",
                  "<i class='fa fa-chevron-right'></i>",
                ]}
                navContainerClass={owlStyle.containerNav}
                navClass={["navPrev", "navNext"]}
                items={3}
                autoplay={true}
                autoplayTimeout={4000}
                autoplayHoverPause={true}
                responsive={{
                  0: {
                    items: 1,
                    autoplay: true,
                  },
                  600: {
                    items: 2,
                    autoplay: true,
                  },
                  1000: {
                    items: 3,
                    autoplay: true,
                  },
                }}
              >
                {topUniversityList &&
                  topUniversityList.univercity_list?.map((item, index) => (
                    <div key={index} className="thumbnail item col fea_university_col">
                      <div className="fev-colleges">
                        <div className="fea-university_year text-right ml-auto">
                          {item.established}
                        </div>
                        <div className="fea-university_image">
                          <img src={`${baseUrl}${item.logo}`} alt="" />
                        </div>
                        <div className="fea-university_body">
                          <h3 className="fea-university_title">
                            <Link to="#">{item.name}</Link>
                          </h3>
                        </div>
                        <div className="fea-university_footer">
                          <div className="fea-university_footer_content d-flex flex-row align-items-center justify-content-between">
                            <div className="fea-university_info">
                              <div className="fea-university_state">
                                <i className="fa fa-graduation-cap" aria-hidden="true" />
                                {item.state}
                              </div>
                            </div>
                            <div className="fea-university_info">
                              <div className="fea-university_text">
                                <i className="fa fa-star" aria-hidden="true" />
                                {item.university_type.split(" ")[0]}
                              </div>
                            </div>
                          </div>
                          <div className="fea-university-button"> 
                            <Link className="btn fea-button" to={`/university/${item.name}`}>
                              View More About University 
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>

                  ))}
              </OwlCarousel>


            )}
          </div>
        </div>
      </div>
    </>
  );
}
