import React, { useEffect, useState } from 'react';
import CommonLayout from '../layouts/CommonLayout';
import { useParams } from 'react-router-dom';
import './videdetails.css';
import uniqid from 'uniqid';
import { apiBaseUrl, baseUrl } from '../config';
import OwlCarousel from 'react-owl-carousel';
import owlStyle from '../assets/styles/ModuleStyle/customOwl.module.css';
import { Link } from 'react-router-dom';
import ExpertAdvice from '../components/ExpertAdvice';
import NewsLetter from '../components/NewsLetter';
// import AOS from 'aos';
// import 'aos/dist/aos.css';

function Videodetails() {
    const { videoId } = useParams();
    const [videodetailsdata, setVideodetailsdata] = useState([]);
    const [expertReview, setExpertReview] = useState([]);
    const [loading, setLoading] = useState(false);

    // Initialize AOS for animations
    // useEffect(() => {
    //     AOS.init();
    // }, []);

    // Fetch video details
    useEffect(() => {
        const fetchVideoDetails = async () => {
            try {
                const response = await fetch(`${apiBaseUrl}expert-review-list-details/${videoId}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setVideodetailsdata(data?.review_list || []);
            } catch (error) {
                console.error('Error fetching video details:', error);
            }
        };

        fetchVideoDetails();
    }, [videoId]);

    // Fetch expert reviews
    useEffect(() => {
        const fetchExpertReviews = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${apiBaseUrl}expert-review-list`);
                const data = await response.json();
                setLoading(false);
                if (data.status === 'success') {
                    setExpertReview(data?.review_list || []);
                } else {
                    console.error('Invalid response format:', data);
                }
            } catch (error) {
                console.error('Error fetching expert reviews:', error);
                setLoading(false);
            }
        };

        fetchExpertReviews();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <CommonLayout>
            <div className="university video_title">
                <div className="breadcrumbs_container compair_crumbs">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="university_title">Video</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="video-play pb-4">
                <div className="container" >
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-10">
                            <div className="embed-responsive embed-responsive-16by9">
                                {videodetailsdata.length > 0 && (
                                    <iframe
                                        className="embed-responsive-item"
                                        src={videodetailsdata[0]?.video_url}
                                        title={videodetailsdata[0]?.title}
                                    ></iframe>
                                )}
                            </div>

                            {videodetailsdata.length > 0 && (
                                <div className="video-tital mt-4">
                                    <h4 className="font-weight-bold">{videodetailsdata[0]?.title}</h4>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="video-row">
                <div className="container" data-aos="fade-in">
                    <OwlCarousel
                        id="University_experts"
                        className="owl-carousel owl-theme row"
                        loop
                        nav
                        dots={false}
                        stageOuterClass={owlStyle.owlStageOuterSpec}
                        navText={[
                            "<i class='fa fa-chevron-left'></i>",
                            "<i class='fa fa-chevron-right'></i>",
                        ]}
                        navContainerClass={owlStyle.containerNav}
                        navClass={['navPrev', 'navNext']}
                        items={3}
                        autoplay={true}
                        autoplayHoverPause={true}
                        responsive={{
                            0: {
                                items: 1,
                                autoplay: true,
                            },
                            600: {
                                items: 2,
                                autoplay: true,
                            },
                            1000: {
                                items: 3,
                                autoplay: true,
                            },
                        }}
                    >
                        {expertReview.map((item) => (
                            <div
                                key={uniqid()}
                                className="expertwrap thumbnail item col fea_university_col"
                                // data-aos="fade-in"
                                // data-aos-delay={100}
                            >
                                <div className="fev-colleges">
                                    <div className="expert-wrap fea-university_image">
                                        <Link to={`/university/videodetails/${item.universities_id}/${item.id}`}>
                                            <img
                                                src={`${baseUrl}${item.video_thumbnail}`}
                                                alt={item.title}
                                            />
                                        </Link>
                                        <div className="youtube-icon">
                                            <Link to={`/university/videodetails/${item.universities_id}/${item.id}`}>
                                                <i className="uil uil-youtube" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="fea-university_body">
                                        <h3 className="fea-university_title">
                                            <Link to="#">{item.title}</Link>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </OwlCarousel>

                    <div class="row">
					<div class="col-md-12">
						 
						<div class="confused_wrap rounded-6">
							<div class="container"  data-aos="fade-in" >
								<div class="row">
									 <div class="col-md-12">
										<div class="advice_wrap">
											<div id="e_loan" class=" mb-3 text-center">
											     <a type="button" data-toggle="modal" data-target="#modalLoginForm" class="btn-outline-dark">Confused ? Get Advice from Expert !</a>
											</div>
										</div> 
									 </div>
								</div>
							</div>
						</div>
					
					</div>
					
				</div>
                </div>
            </div>
            <ExpertAdvice getModal="#modalLoginForm2" />
            <NewsLetter />
        </CommonLayout>
    );
}

export default Videodetails;
