import React, { useEffect, useState } from 'react';
import CrStyle from "../../assets/styles/ModuleStyle/course.module.css";
import ReCAPTCHA from "react-google-recaptcha";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiBaseUrl } from '../../config';

export default function WriteAReview({ universityname }) {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch the list of courses from the API
    const fetchCourses = async () => {
      try {
        const response = await fetch(`${apiBaseUrl}univercity-course-list/${universityname}`);
        const data = await response.json();
        setCourses(data?.course_list);
      } catch (err) {
        console.log(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, [universityname]);


  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    course: '',
    message: ''
  });
  const [isVerified, setIsVerified] = useState(false);

  const handleRecaptcha = (value) => {
    const timeout = new Promise((_, reject) =>
      setTimeout(() => reject(new Error("ReCAPTCHA Timeout")), 5000) // 5 seconds
    );

    Promise.race([
      new Promise((resolve) => resolve(!!value)),
      timeout,
    ])
      .then((result) => setIsVerified(result))
      .catch((error) => console.error("ReCAPTCHA Error:", error));
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isVerified) {
      alert("Please verify the CAPTCHA to proceed.");
      return;
    }

    const payload = {
      univercity_name: universityname,
      name: formData.name,
      email: formData.email,
      phone: formData.mobile,
      course_id: formData.course,
      message: formData.message,
    };

    console.log('payload', payload)

    try {
      const response = await fetch(`${apiBaseUrl}submit-univercity-review`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const result = await response.json();
        toast.success("Review submitted successfully!");
        setFormData({
          name: '',
          email: '',
          mobile: '',
          course: '',
          message: ''
        });
        setIsVerified(false);
      } else {
        const error = await response.json();
        toast.error(`Failed to submit review: ${error.message || "Unknown error"}`);
      }
    } catch (error) {
      console.error("Error submitting review:", error);
      toast.error("An error occurred while submitting the review.");
    }
  };


  return (
    <div id="review_wrapper" className="review_wrapper section bg-light">
      <ToastContainer position="top-right" autoClose={3000} />
      <div className="container" data-aos="fade-up">
        <div className="row">
          <div className="col pb-4">
            <div className="section_title text-left">
              <h2 className="section_title"> Write A Review</h2>
            </div>
          </div>
        </div>
        <div className="row pt-3 pt-md-4">
          <div className={CrStyle.review_form_col}>
            <form onSubmit={handleSubmit} className={CrStyle.review_form}>
              <div className="row">
                <div className="col-lg-6 form-group">
                  <div className={`${CrStyle.form_title} form_title`}>Name</div>
                  <input
                    name="name"
                    type="text"
                    className={`${CrStyle["form-control"]} form-control`}
                    required
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6 form-group">
                  <div className={`${CrStyle.form_title} form_title`}>Email</div>
                  <input
                    name="email"
                    type="email"
                    className={`${CrStyle["form-control"]} form-control`}
                    required
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6 form-group">
                  <div className={`${CrStyle.form_title} form_title`}>Mobile</div>
                  <input
                    name="mobile"
                    type="tel"
                    className={`${CrStyle["form-control"]} form-control`}
                    required
                    value={formData.mobile}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6 form-group mt-lg-0 mt-3">
                  <div className={`${CrStyle.form_title} form_title`}>Select Course</div>
                  <select
                    name="course"
                    className={`${CrStyle["form-control"]} form-control`}
                    value={formData.course}
                    onChange={handleChange}
                  >
                    <option value="">Choose Course</option>
                    {courses?.map((item, index) => (
                      <option key={index} value={item?.name}>
                        {item?.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-lg-12 form-group">
                  <div className={`${CrStyle.form_title} form_title`}>Message</div>
                  <textarea
                    name="message"
                    rows={5}
                    className={`${CrStyle["form-control"]} form-control`}
                    required
                    value={formData.message}
                    onChange={handleChange}
                  />
                </div>
                <div className="py-2 text-start d-block">
                  <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} onChange={handleRecaptcha} />
                </div>
                <div>
                  <button type="submit" className="comment_button trans_200">
                    submit now
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
