import React from "react";
import Style from "../../assets/styles/ModuleStyle/course.module.css";
import SingleRecentPost from "../Elements/SingleRecentPost";
import { Link } from "react-router-dom";
export default function GyanBlogSideBar({
  isMba = false,
  recentPost,
  categoryList,
}) {
  return (
    <div className={`${Style.sidebar} sidebar`}>
      {/* Latest News */}
      {/* <div className={Style.sidebar_section}>
        <div className={Style.sidebar_section_title}>Recent Post</div>
        <div className={`${Style.sidebar_latest} ${Style.Single_Blogs}`}>
          {recentPost?.map((item, index) => (
            <SingleRecentPost
              key={index}
              title={item.title}
              date={item.date_time}
            />
          ))}
        </div>
      </div> */}
      {/* Categories */}
      {/* <div className={Style.sidebar_section}>
        <div className={Style.sidebar_section_title}>Categories</div>
        <div className={Style.sidebar_categories}>
          <ul className={Style.categories_list}>
            {categoryList?.map((item, index) => (
              <li>
                <Link to="#" className="clearfix d-flex">
                  <div className="col-8" style={{ overflowX: "auto",textWrap:"nowrap" }}>
                    {item.name}
                  </div>
                  <div className="col-4">
                    <span>{`(${item.no_of_blog})`}</span>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div> */}
      {/* Gallery */}
      <div className={Style.sidebar_section}>
        <div className={Style.sidebar_section_title}>Talk to Our Experts</div>
        <div className={Style["sidebar_talk-expert"]}>
          <form action="#" className="comment_form">
            <div className="input_col">
              <div className="form_title">Name*</div>
              <input
                type="text"
                className="comment_input"
                required="required"
              />
            </div>
            <div className="input_col">
              <div className="form_title">Email*</div>
              <input
                type="text"
                className="comment_input"
                required="required"
              />
            </div>
            <div className="input_col">
              <div className="form_title">State*</div>
              <input
                type="text"
                className="comment_input"
                required="required"
              />
            </div>
            <div className="input_col">
              <div className="form_title">Phone No*</div>
              <input
                type="text"
                className="comment_input"
                inputMode="numeric"
                pattern="[0-9]*"
                required="required"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />
            </div>
            <div>
              <button type="submit" className="comment_button trans_200">
                submit
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* Tags */}
      <div
        className="sidebar_section "
        style={isMba === true ? { display: "block" } : { display: "none" }}
      >
        <div className="sidebar_image">
          <img className="img-fluid" src="/image/ads/side add 3.webp" alt="" />
        </div>
      </div>
    </div>
  );
}
