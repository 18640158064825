import React, { useEffect, useState } from 'react'
import { apiBaseUrl, baseUrl } from '../config';
import { useParams } from "react-router-dom";
import CommonLayout from '../layouts/CommonLayout';
import CourseNameDetails from '../components/CourseComponents/CourseNameDetails';
import { useDispatch, useSelector } from 'react-redux';
import SubHeader from '../components/CourseComponents/SubHeader';
import JulyOfferWithCarousel from '../components/CourseComponents/JulyOfferWithCarousel';
import CrStyle from "../assets/styles/ModuleStyle/course.module.css";
import GetAssistanceBox from '../components/CourseComponents/GetAssistanceBox';
import UgcAdd from '../components/CourseComponents/UgcAdd';
import CourseSpecification from '../components/CourseComponents/CourseSpecification';
import UniversitySummary from '../components/CourseComponents/UniversitySummary';
import CourseFee from '../components/CourseComponents/CourseFee';
import FacultyDetails from '../components/CourseComponents/FacultyDetails';
import StudentManse2 from '../components/CourseComponents/StudentManse2';
import LatestUpdates from '../components/LatestUpdates';
import uniqid from "uniqid";
import { setBannerArr } from "../reducer/universitySlice";
import Blogs from '../components/Blogs';
import TopUniversity from '../components/TopUniversity';
import Faqs from '../components/CourseComponents/Faqs';
import ExpertAdvice from '../components/ExpertAdvice';
import InNews from '../components/InNews';
import NewsLetter from '../components/NewsLetter';
import WriteAReview from '../components/CourseComponents/WriteAReview';
import { useLocation } from "react-router-dom";
import CourseSpecilationBlogs from '../components/CourseSpecilationBlogs';


function CourseSpecializationDetails() {
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [subcoursedata, setsubcoursedata] = useState();
    const [masterdata, setmasterdata] = useState();
    const { name } = useParams();
    const dispatch = useDispatch();
    const { uniName } = location.state || {};
    const universityname = masterdata?.univercity_name;
    const universityid = masterdata?.univercity_id;
    ;
    console.log('subcoursedata', subcoursedata);



    const subheaderContent = [
        {
            title: "Overview",
            link: "#overview",
        },
        {
            title: "Offers",
            link: "#offers",
        },
        {
            title: "Specification",
            link: "#Specification",
        },
        {
            title: "Summary",
            link: "#summary",
        },
        {
            title: "Brochure",
            link: "#Brochure",
        },
        {
            title: "Compare",
            link: "#Compare",
        },
        {
            title: "Expert Review",
            link: "#Expert_Review",
        },
        {
            title: "faculty",
            link: "#faculty",
        },
        {
            title: " Student Mann Se",
            link: "#Student_Mann_Se",
        },
        {
            title: "  Latest Update",
            link: "#Latest_Update",
        },
        {
            title: "Blogs",
            link: "#Blogs",
        },
        {
            title: "Faq's",
            link: "#Faqs",
        },
        {
            title: "Expert Advice",
            link: "#Expert_Advice",
        },
        {
            title: "Contact",
            link: "#review_wrapper",
        },
    ];
    const bannerBox = useSelector((state) => state.mainuniversity?.bannerArr);
    const courseData = useSelector((state) => state.course?.courseInfo);
    const manSe = courseData?.student_manse;
    const getSubCourseDetails = async () => {
        setIsLoading(true); 
        try {
            const response = await fetch(`${apiBaseUrl}couse-specialization-details/${name}`);
            const data = await response.json();
            if (data.status === "success") {
                setmasterdata(data);
                setsubcoursedata(data?.specilization_details);
                dispatch(setBannerArr(data?.banner));
            }
        } catch (error) {
            console.error("Error fetching course details:", error);
        } finally {
            setIsLoading(false); 
        }
    };
    const mainUniversity = useSelector((state) => state.mainuniversity?.university);
    const uniDetails = masterdata?.univercity_faculty.map((item) => item);
    console.log('uniDetails', uniDetails);

    const specificationOpt = [
        {
            title: "Specialization",
            value:
                subcoursedata?.specialization === "" ||
                    subcoursedata?.specialization === "-"
                    ? "-"
                    : subcoursedata?.specialization,
        },
        {
            title: "Placement Assistance",
            value: subcoursedata?.placement,
        },
        {
            title: "Education Model",
            value: subcoursedata?.education_model,
        },
        {
            title: "Loan and EMI",
            value: subcoursedata?.loan_emi,
        },
        {
            title: "Alumni Status",
            value:
                subcoursedata?.alumni_status === "" ||
                    subcoursedata?.alumni_status === "-"
                    ? "-"
                    : subcoursedata?.alumni_status,
        },
        {
            title: "UG Rating",
            value: subcoursedata?.ug_rating,
        },
    ];

    const courseList = [
        {
            coursename: subcoursedata?.name,
            university_id: universityid,
            specialization:
                subcoursedata?.specialization === "-" ||
                    subcoursedata?.specialization === ""
                    ? ""
                    : ` ${subcoursedata?.specialization}+ Specialization`,
            totalfees: subcoursedata?.fees_amount,
            completefees:
                subcoursedata?.complete_fees === ""
                    ? ""
                    : `${baseUrl}${subcoursedata?.complete_fees}`,
        },
    ];

    const universityId = useSelector(
        (state) => state.mainuniversity?.universityId
    );
    const uniId = useSelector((state) => state.mainuniversity?.universityId);

    useEffect(() => {
        getSubCourseDetails();
    }, [name])

    return (
        <>
            <CommonLayout>
                <CourseNameDetails
                    rating={subcoursedata?.rate}
                    courseName={subcoursedata?.name}
                    universityName={masterdata?.course_name}
                    reviewCount={subcoursedata?.total_review}
                />
                <SubHeader subheaderContent={subheaderContent} />
                <JulyOfferWithCarousel
                    banner={bannerBox && bannerBox}
                    details={subcoursedata && subcoursedata}
                />

                {/* Course */}
                <div id="Courses_All" className={`${CrStyle.course} course`}>
                    <div className="container">
                        <div className="row">
                            {/* Course */}
                            <div className="col-lg-8">
                                <div className={CrStyle.course_container}>
                                    <GetAssistanceBox
                                     key={uniqid()}
                                        whatabout={`Contact ${masterdata?.univercity_name} Now `}
                                        subtext="Get in touch with a University Authorized Representative"
                                        buttonName="Get Assistance"
                                    />
                                    <UgcAdd key={uniqid()} imgSrc={`${baseUrl}${subcoursedata?.small_banner}`} />
                                    <CourseSpecification
                                    key={uniqid()}
                                        boxValue={specificationOpt}
                                        title={`${masterdata?.course_name} Specification`}
                                    />
                                    {/*-University VIEW MORE / LESS---*/}
                                    <UniversitySummary
                                    key={uniqid()}
                                        mainId="summary"
                                        mainclassName="univer_Summary mt-4"
                                        title="Course Summary "
                                        defaultShow={subcoursedata?.short_summary}
                                        details={subcoursedata?.summary}
                                    />

                                    <GetAssistanceBox
                                    key={uniqid()}
                                        whatabout="University Online Brochure"
                                        subtext={`Download ${subcoursedata?.name} in Just one click to view all course & Fee`}
                                        buttonName="Download Brochure"
                                        downloadFile={subcoursedata?.online_broucher}
                                        buttonclassName="col-lg-4"
                                        textColclassName="col-lg-8"
                                        mainclassName="mt-5"
                                        id="Brochure"
                                    />

                                    <CourseFee  key={uniqid()} courseList={courseList} />
                                    <UniversitySummary
                                    key={uniqid()}
                                        mainId="Expert_Review"
                                        mainclassName="uni_Expert_wrapper mt-5"
                                        title="Course Online Expert Review"
                                        defaultShow={subcoursedata?.short_online_expert_review}
                                        details={subcoursedata?.online_expert_review}
                                    />

                                    <FacultyDetails
                                    key={uniqid()}
                                        text={masterdata?.specilization_details?.faculty}
                                        uniLogo={`${baseUrl}${uniDetails?.image}`}
                                        facultyList={uniDetails && uniDetails}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <StudentManse2 key={uniqid()} details={masterdata?.student_manse} />
                <LatestUpdates key={uniqid()} condition={uniId && uniId} uniName={universityname}/>

                <CourseSpecilationBlogs key={uniqid()} uniId={uniId && uniId} uniName={universityname}/>
                <TopUniversity />

                <Faqs key={uniqid()} data={masterdata && masterdata.faq} uniname={subcoursedata?.name} />
                <WriteAReview universityname={universityname}/>
                <ExpertAdvice getModal="#modalLoginForm2" />
                <InNews />
                <NewsLetter />

            </CommonLayout>

        </>
    )
}

export default CourseSpecializationDetails